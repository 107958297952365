import React from 'react';
import OnboardMSP from 'views/OnboardMSP';
import Main from 'layouts/Main';
import WithLayout from 'WithLayout';
import { Helmet } from 'react-helmet';

const title = 'Welcome';

const OnboardMSPViewPage = () => {
  return (
    <>
    <Helmet
      defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
      title={title}
      titleTemplate="%s | WindRate.com"
    />    
    <WithLayout
      component={OnboardMSP}
      layout={Main}
    />
    </>
  )
};

export default OnboardMSPViewPage;
